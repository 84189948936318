import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Users } from '../models/users.model';
import * as customConfig from 'src/assets/customConfig.json';

@Injectable({
  providedIn: 'root'
})

export class UserServiceFredd {

    constructor(public http:HttpClient){}
    readonly rootUrl: string = customConfig.cSharpApi;
    getData(){
       return this.http.get("../../../../assets/UserPermissions.json");
    }
    getUserById(Id:number){
      return this.http.get(this.rootUrl + "Users/"+Id);
     }
     getUserByIdForAdmin(Id:number){
      return this.http.get(this.rootUrl + "Users/GetUserForAdmin/"+Id);
     }
    getUsers(){
     return this.http.get(this.rootUrl + "Users")
    
    }

    post(user:Users){

      return this.http.post(this.rootUrl +"Users/NewUser",user)
      //return this.http.post("https://localhost:44317/api/Users/NewUser",user)
    }
    put(Id:any , user:any){

      return this.http.put(this.rootUrl +"Users/"+Id,user);
    }
    delete(Id:any , user:any){

      return this.http.delete(this.rootUrl +"Users/"+Id);
    }
    deleteAzure(user:any){
      return this.http.delete("https://api-bmr-wus-fredd-extranet-user-prod.azurewebsites.net/api/UserADB2C/Delete/"+user)
    }

}
