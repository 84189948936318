import { Component, OnInit, ViewChild } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/app/services/base/user.service';
import { CrmApiService } from 'src/app/services/base/crm-api.service';
import { ApiService } from 'src/app/services/base/api.service';
import { AuthenticationService } from 'src/app/helper/authentication.service';
import { MatSort } from "@angular/material/sort";
import { TenantService } from '../../fredd/services/tenant.service';
import { MarketService } from '../../freddweb/services/market.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DownloadCopyComponent } from "src/app/modules/fredd/components/download-copy/download-copy.component";
import * as customConfig from 'src/assets/customConfig.json';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PropertyService } from '../../freddweb/services/property.service';
import { UserServiceFredd} from '../../freddweb/services/user.service'
import { BaseService } from 'src/app/services/base/base.service';



@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public routeSerice: RouteService,
    public router: Router,
    public authenticate: AuthenticateService,
    public authenticationService: AuthenticationService,
    private authService: MsalService,
    public userService: UserService,
    public crmApiService: CrmApiService,
    public UserServiceFredd:UserServiceFredd,
    public apiService: ApiService,
    public Tenantservice: TenantService,
    public UserService: UserService,
    public PropertyService: PropertyService,
    public Marketservice: MarketService,
    public dialogService: DialogService,
    public baseService:BaseService
  ) { }
  hasChild = (_: number, node: UserPermission) =>
    !!node.children && node.children.length > 0;

  ngOnInit(): void {
    var item = localStorage.getItem('accountExpired');
    if (item == "false") {
      this.router.navigate(['authenticate']);
    }
    this.routeSerice.subApp.title = "";
    this.routeSerice.subApp.route = "";
    this.getTreeData();
    this.getUserIsAdmin();
    //this.getProfile();
  }


  openLink(module: string, routeName: string) {
    this.router.navigate([this.routeSerice.openRoute(module, routeName)]);
  }

  logout() { // Add log out function here
    this.authenticationService.logout()
  }

  Market: string;
  Property: string;
  Maindata: any = [];
  breadcrum: string = null;
  data: any[] = []
  Filtereddata: any[] = []
  excludedDocuments: any[]=[]
  displayedColumns: string[] = ['Name','DocumentDate'];
  dataSourcetable = new MatTableDataSource<any>();
  treeControl = new NestedTreeControl<UserPermission>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    //  open admin portal component
  openAdminPortal() {
    this.router.navigate(['app/home/admin/portal'])
  }
  // for tree 
  getTreeData() {
    this.Marketservice.getUserById().toPromise().then((res: any) => {
      this.excludedDocuments = res.users_ExcludedDocuments;
      this.Marketservice.getAllTreeData().toPromise().then((res: any) => {
        this.dataSource = res;
        
        this.Maindata = res;

    });
  });


  }

  containsSpecialChars(str) {
    const specialChars = /＆/;
    return specialChars.test(str);
  }
// selected property document
  getPropertyDocuments(items: any, node: any) {
    var arraySplit = node.metadata_Tree.split(':');
    node.metadata_Tree = node.metadata_Tree.replace('%EF%BC%86', decodeURI('%EF%BC%86'))
    this.PropertyService.getDocuments(arraySplit[1], node.metadata_Tree, "").subscribe((res: any) => {
      this.data = res;
      if(this.excludedDocuments.length>0){
        for(var icount=0;icount<this.excludedDocuments.length;icount++){
          var Items = this.excludedDocuments[icount].documentName.split(";");
          var filter =this.data.filter(function(x){
          return x.Name ===Items[1]
          })
          //var filter =this.data.findIndex(x => x.Name ===Items[1]);
          if(filter.length >0)
            this.data.splice(filter[0], 1);
        }
      }
      this.dataSourcetable = new MatTableDataSource(res);
      this.dataSourcetable.paginator = this.paginator;
      this.dataSourcetable.sort = this.sort;
    })
    // for breadcrum
    var replVal = /:/gi;
    this.breadcrum = node.metadata_Tree.replace(replVal, "/")
  }
  // for search specific document
  search(event: any) {
    if (event.value || event.value == "") {
      const filterValue = (event as HTMLInputElement).value;
      this.dataSourcetable.filter = filterValue.trim().toLowerCase();
    }
    else {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSourcetable.filter = filterValue.trim().toLowerCase();
    }
  }
  // for open alert yes no 
  opendialog(row: any) {
    this.downloadPdf(row);
  }
  // for download view document
  downloadPdf(row: any) {
    this.dialogService.openSmallDialog(DownloadCopyComponent);
    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
        var myUrl: string = row.URL;
        if (result == "view") {
          this.PropertyService.fetch("GetDownloadedDocument", myUrl, row.Name);
        }
        else if (result == "download") {
          this.PropertyService.downloadDocument(row.URL, row.Name);
        }
        else if (result == "openIntab") {
          var url = customConfig.freddDocumentDownlodLink + myUrl;
          window.open(url, "_blank");
        }
      }
    });

  }
  getUserIsAdmin(){
    this.userService.getCurrecntUser().subscribe((res:any)=>{
      this.baseService.username = res.name;
      var splitname = this.baseService.username.split(" ");
      if(splitname.length  > 0){
        this.baseService.profileName = splitname[0].substring(0,1);
        if(splitname.length  > 1)
          this.baseService.profileName += splitname[1].substring(0,1);

        this.baseService.profileName = this.baseService.profileName.toUpperCase();
      }
      if(res.users_Admin.length > 0){
        this.baseService.IsAdmin = true;
      }
    })
  }


}

interface UserPermission {
  metadata: string;
  children?: UserPermission[];
  guid: string;
  parentId: string;
}
