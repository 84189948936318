import { filter } from 'rxjs/operators';
// angular
import { Component, OnInit,  } from '@angular/core';

import { Router } from '@angular/router';
// Base
import { UserService } from 'src/app/services/base/user.service';
import { BaseService } from 'src/app/services/base/base.service';
 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Customer Relationship Management';
  public searchInput = false;
  contactData: any[] = [];
  isLoading = false;
  errorMsg: string;
  userManualLink: string = "";
  feedBackLink: string = "";
  settlementTamplateLink: string = "";
  contractTamplateLink: string = "";
  siteContentLink: string = "";
  homepage: string = "";
  homepageTitle: string = "";
  fredd: string = "";
  freddTitle: string = "";
  userName:string = "";
  public AppLogoImg = "";

  constructor(
    public userService: UserService,
    public router: Router,
    public baseService:BaseService
  ) {
    //this.common.getSectionData();

  }

  intervalId: any;


  ngOnInit() {
    this.getUserIsAdmin()
  }

  getUserIsAdmin(){
    this.userService.getCurrecntUser().subscribe((res:any)=>{
      this.userService.getCurrecntUser().subscribe((res:any)=>{
        this.baseService.username = res.name;
        var splitname = this.baseService.username.split(" ");
        if(splitname.length  > 0){
          this.baseService.profileName = splitname[0].substring(0,1);
          if(splitname.length  > 1)
            this.baseService.profileName += splitname[1].substring(0,1);
            
          this.baseService.profileName = this.baseService.profileName.toUpperCase();
        }
        if(res.users_Admin.length > 0){
          this.baseService.IsAdmin = true;
        }
      })
      if(res.users_Admin.length > 0){
        this.baseService.IsAdmin = true;
      }
    })
  }

}

