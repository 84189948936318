import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteService } from './services/base/route.service';

//import { SqlService } from './services/base/sql.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './services/base/interceptor/http-config.interceptor';
import { BaseService } from './services/base/base.service';
import { NgxSpinnerModule } from 'ngx-spinner';

import { PageNotFoundComponent } from './modules/main/page-not-found/page-not-found.component';
import { BaseComponent } from './modules/base/base.component';
import { AppInjectorService } from './services/base/app-injector.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { WindowRefService } from './services/base/window-ref.service'

// MSAL/Authentication libraries
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular'; // Import MsalInterceptor
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

import * as customConfig from 'src/assets/customConfig.json';
import { SigninComponent } from './modules/main/signin/signin.component';
import { AdminPortalComponent } from './modules/main/admin/admin-portal/admin-portal.component';
import { AuthenticateComponent } from './modules/main/authenticate/authenticate.component';
import { DatePipe } from '@angular/common';
import { RegisterComponent } from './modules/main/register/register.component';



@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,
    PageNotFoundComponent,
    BaseComponent,
    SigninComponent,
    AdminPortalComponent,
    AuthenticateComponent,
    RegisterComponent

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // MsalModule.forRoot(new PublicClientApplication({
    //   auth:{
    //     clientId:customConfig.clientId, // Application Id of Application registered in B2C
    //     authority:customConfig.authority, //signup-signin userflow
    //     validateAuthority:false,
    //     redirectUri:customConfig.redirectUri
    //   },
    //   cache:{
    //     cacheLocation:"sessionStorage",
    //     storeAuthStateInCookie:false
    //   }
    // }),{
    //   consentScopes:customConfig.consentScopes
    // })
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: customConfig.clientId, // This is your client ID
        authority: customConfig.authority, // This is your tenant ID
        knownAuthorities: [customConfig.authorityDomain],
        redirectUri: customConfig.redirectUri, // This is your redirect URI,
        
        //navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      },
    }), {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['https://bmrdevb2c.onmicrosoft.com/api/read_data'],
        }
      }, {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map(
          [
            ["https://graph.microsoft.com/v1.0/me", [customConfig.scopeprofile]],
            ["https://graph.microsoft.com/v1.0/me", [customConfig.scopeopenid]]
        ]
        ),
        // protectedResourceMap: new Map([
        //   ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        //   [customConfig.scopeName, [customConfig.scope]],
        // ]),
        
        
      })
  ],
  providers: [
    BaseService,
    RouteService,
    DatePipe,
    WindowRefService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }        
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjectorService.injector = injector;
  }
}
